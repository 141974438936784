import { useEffect, useState } from 'react';
import { getText } from '@veraio/strank';
import { pick, isNil } from '@veraio/core';
import { useLocations } from '@oneecosystem/dealshaker-core';
import { toast } from 'react-hot-toast';
import { Dropdown } from 'components/UIExternal';
import { DealFeeConfiguration, DistributionFeeConfiguration, FormBox, ClearingPeriods } from 'components/screens';
import {
  getAllConfigs,
  getConfigsByCountry,
  setError,
  updateConfig,
  updateConfigByCountry,
  deleteConfigByCountry,
} from 'services';
import { DISTRIBUTION_FEE, DEAL_FEE, CONFIG_GROUP, CLEARING_AND_SETTLEMENT } from 'enums';
import { configurationsContainer, countriesDropdown } from './styles';

export const CountryConfigurations = () => {
  const countries = useLocations((locationsState) => locationsState.countries);
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [configurations, setConfigurations] = useState(null);
  const hasSelectedCountry = !isNil(selectedCountryId);

  useEffect(() => {
    fetchConfigurations();
  }, [selectedCountryId]);

  const fetchConfigurations = async () => {
    const request = hasSelectedCountry ? getConfigsByCountry : getAllConfigs;
    const params = hasSelectedCountry ? [selectedCountryId] : [];
    const [res, err] = await request(...params);
    if (err) return setError(err);

    setConfigurations({
      [CONFIG_GROUP.DEAL_FEE]: pick(res, Object.values(DEAL_FEE)),
      [CONFIG_GROUP.DISTRIBUTION_FEE]: pick(res, Object.values(DISTRIBUTION_FEE)),
      [CONFIG_GROUP.CLEARING_AND_SETTLEMENT]: pick(res, Object.values(CLEARING_AND_SETTLEMENT)),
    });
  };

  const updateConfigs = async (groupName, data) => {
    const request = hasSelectedCountry ? updateConfigByCountry : updateConfig;
    const params = [groupName, data].concat(selectedCountryId ? [selectedCountryId] : []);
    const [, err] = await request(...params);
    if (err) return setError(err);

    const countryName = countries.find((x) => x.id === selectedCountryId)?.name;
    toast.success(
      getText(hasSelectedCountry ? 'successfullyEditedConfigCountry' : 'successfullyEditedConfig', { countryName }),
    );
    fetchConfigurations();
  };

  const resetConfigs = async (groupName) => {
    const [, err] = await deleteConfigByCountry(groupName, selectedCountryId);
    const countryName = countries.find((x) => x.id === selectedCountryId).name;
    if (err) return setError(err);

    fetchConfigurations();
    toast.success(getText('successfullyDeleteConfigCountry', { countryName }));
  };

  const configProps = (groupName) => ({
    groupName,
    hasSelectedCountry,
    data: configurations?.[groupName],
    onUpdate: (data) => updateConfigs(groupName, data),
    onReset: () => resetConfigs(groupName),
  });

  return (
    <>
      <Dropdown
        small
        withSearch
        placeholder={getText('worldWide')}
        options={countries}
        onChange={(val) => setSelectedCountryId(val?.id)}
        displayKey="name"
        uniqueKey="id"
        css={countriesDropdown}
      />
      <section css={configurationsContainer}>
        <FormBox title={getText('dealFee')}>
          <DealFeeConfiguration {...configProps(CONFIG_GROUP.DEAL_FEE)} />
        </FormBox>
        <FormBox title={getText('distributionFee')} subTitle={getText('configMaxAllowedPercent')}>
          <DistributionFeeConfiguration {...configProps(CONFIG_GROUP.DISTRIBUTION_FEE)} />
        </FormBox>
        <FormBox title={getText('clearingPeriods')}>
          <ClearingPeriods {...configProps(CONFIG_GROUP.CLEARING_AND_SETTLEMENT)} />
        </FormBox>
      </section>
    </>
  );
};
