import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row, Spin } from 'antd';
import { useTranslations } from '@veraio/strank';
import { toast } from 'react-hot-toast';
import { Button, Tag } from 'components/UIExternal';
import DeclineModal from 'components/ui/DeclineModal';
import CommentModal from 'components/ui/CommentModal';
import { Icon } from 'components/ui';
import { businessStatus, businessAddressStatus, businessAddressStatusInverse, businessAddressStatusColor } from 'enums';
import { formatDefaultDateTime, useParamsInt } from 'utils';
import {
  getBusinessAddressById,
  updateBusinessAddressStatus,
  addCommentToBusinessAddress,
  updateBusinessAddress,
} from 'services';
import useError from 'services/errorHandling/useError';
import { AddressCard } from 'components/shared';
import { mb } from 'styles';
import { spinner, navigationWrapper } from './styles';

const AddressDetail = () => {
  const history = useHistory();
  const { getText, getDynamicTranslation } = useTranslations();
  const { setError } = useError();
  const addressId = useParamsInt('addressId');

  const [address, setAddress] = useState();
  const [isDeclineModalVisible, setIsDeclineModalVisible] = useState(false);
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);

  useEffect(() => {
    fetchBusinessAddress();
  }, [addressId]);

  const fetchBusinessAddress = async () => {
    const [res, err] = await getBusinessAddressById(addressId);
    err ? setError(err) : setAddress(res);
  };

  const handleEditBusinessAddress = async (data) => {
    const [, err] = await updateBusinessAddress(addressId, data);
    if (err) return setError(err);
    toast.success(getText('successfullyEditedBusinessAddress'));
    await fetchBusinessAddress();
  };

  const handleEditBusinessAddressStatus = async (status, reason) => {
    const [, err] = await updateBusinessAddressStatus({ id: addressId, status, reason });
    err ? setError(err) : fetchBusinessAddress();
    setIsDeclineModalVisible(false);
  };

  const handleAddCommentToBusinessAddress = async (comment) => {
    const [, err] = await addCommentToBusinessAddress({ id: addressId, comment });
    err ? setError(err) : fetchBusinessAddress();
    setIsCommentModalVisible(false);
  };

  return address ? (
    <>
      <div className="inner-content-container">
        <div css={navigationWrapper}>
          <div className="specs-container">
            <Button type="link" onClick={() => history.goBack()} small>
              <Icon iconName="las la-arrow-left" />
            </Button>
            <h2 className="flex flex-center address-name">{getDynamicTranslation(address.addressName)?.name}</h2>
            <Tag type={businessAddressStatusColor[address.statusId]}>
              {getText(businessAddressStatusInverse[address.statusId])}
            </Tag>
          </div>
          <div className="actions-container">
            {address?.statusId !== businessStatus.approved && (
              <Button
                type="secondary"
                small
                onClick={() => handleEditBusinessAddressStatus(businessAddressStatus.approved)}>
                {address?.statusId === businessStatus.edited ? getText('approveChanges') : getText('verifyAddress')}
              </Button>
            )}
            <Button type="secondary" small onClick={() => setIsCommentModalVisible(true)}>
              {getText('comment')}
            </Button>
            {address.statusId !== businessStatus.declined && (
              <Button type="danger" small onClick={() => setIsDeclineModalVisible(true)}>
                {address?.statusId === businessStatus.edited ? getText('declineChanges') : getText('declineAddress')}
              </Button>
            )}
          </div>
        </div>

        {address.comments?.length > 0 && (
          <Row css={mb({ lg: 20, span: 30 })}>
            {address.comments.map((comment) => (
              <Col span={24} key={comment.createdDate}>
                <p>
                  <span className="bolder-text">{`${comment.managerName} [${formatDefaultDateTime(
                    comment.createdDate,
                  )}]: `}</span>
                  {comment.comment}
                </p>
              </Col>
            ))}
          </Row>
        )}

        <Row css={mb({ lg: 30, span: 20 })}>
          <Col className="gutter-row" lg={10} span={24}>
            <h5>{getText('addressInformation')}</h5>
            <AddressCard
              editable
              addressId={address?.id}
              onEdit={handleEditBusinessAddress}
              oldData={address?.currentAddressInformation}
              newData={address?.newAddressInformation}
            />
          </Col>
        </Row>
      </div>
      <DeclineModal
        title={getText('address')}
        isVisible={isDeclineModalVisible}
        onCancel={() => setIsDeclineModalVisible(false)}
        onAccept={(reason) => handleEditBusinessAddressStatus(businessAddressStatus.declined, reason)}
      />
      <CommentModal
        isVisible={isCommentModalVisible}
        onCancel={() => setIsCommentModalVisible(false)}
        onAccept={handleAddCommentToBusinessAddress}
      />
    </>
  ) : (
    <Spin size="large" css={spinner} />
  );
};

export default AddressDetail;
