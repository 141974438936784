import { theme } from 'styles';

export const imagesPreview = {
  '& .ant-upload-list-item.ant-upload-list-item-done': {
    padding: '0 ',
    border: 'none',
  },
  '& .ant-btn.ant-btn-text.ant-btn-sm.ant-btn-icon-only.ant-upload-list-item-card-actions-btn': {
    color: 'white',
  },
  '& .ant-upload-list-item-image': {
    objectFit: 'cover !important',
  },
  '& .ant-upload-list-item-info': {
    borderRadius: 3,
  },
  '& .ant-upload-list-picture-card-container': {
    marginRight: '1.875em',
    borderRadius: '4px !important',
    [theme.Desktop]: { width: '13.75rem', height: '12.5rem' },
    [theme.Mobile]: { width: '6.25rem', height: '5rem' },
  },
};

export const galleryModal = {
  '& .ant-modal-content': {
    [theme.Desktop]: { padding: '0 4.625em' },
    [theme.Mobile]: { padding: '0 0.25em' },
  },
  '& .ant-modal-body': { padding: '0.625em 0' },
  '& .image-gallery-slide': {
    width: '100%',
    [theme.Desktop]: { height: '41.25rem' },
    [theme.Mobile]: { height: '31.25rem' },
    backgroundColor: theme.gray500,
    paddingBottom: '1',
    borderRadius: 4,
  },
  '& .image-gallery-content .image-gallery-slide .image-gallery-image': {
    width: '100%',
    height: '100%',
    position: 'fixed',
  },
  '& .image-gallery-right-nav': {
    padding: 0,
    filter: 'none',
    right: -55,
    [theme.Mobile]: { right: 0 },
    '& svg': {
      color: theme.gray200,
      height: '3.125rem',
      width: '1.875rem',
    },
  },
  '& .image-gallery-left-nav': {
    filter: 'none',
    padding: 0,
    left: -55,
    [theme.Mobile]: { left: 0 },
    '& svg': {
      color: theme.gray200,
      height: '3.125rem',
      width: '1.875rem',
    },
  },
};

export const youTubePlayIcon = {
  position: 'relative',
  '& .la-play': {
    position: 'absolute',
    background: theme.gray_rgba,
    color: 'white',
    left: '1.875rem',
    top: '1.25rem',
    borderRadius: '50%',
    height: '1.25rem',
    width: '1.25rem',
  },
};

export const video = {
  width: '100% !important',
  height: '100% !important',
  position: 'absolute',
  left: 0,
  top: 0,
  '& iframe': {
    height: '100%',
    width: '100%',
  },
};

export const modalActions = {
  display: 'flex',
  marginTop: '1.5em',
};
