import PropTypes from 'prop-types';
import { printPrice } from '@oneecosystem/dealshaker-core';
import { Tag } from 'components/UIExternal';
import { Icon } from 'components/ui';
import { paymentStatusColor, paymentStatusInverse } from 'enums';

export const OrderDetailsPaymentWallet = ({ iconName, title, subTitle, printPriceProps, orderPaymentStatus }) => (
  <div className="order-details-payment-method-card">
    {!!iconName && <Icon iconName={iconName} className="order-details-payment-method-avatar" />}
    <div className="order-details-payment-method">
      <h5>{title}</h5>
      <p className="gray order-details-payment-sub-label">{subTitle}</p>
    </div>
    <div className="order-details-payment-price-container">
      <h5 className="order-details-payment-price">{printPrice(printPriceProps)}</h5>
      <Tag type={paymentStatusColor[orderPaymentStatus]} fontSize="0.8rem">
        {paymentStatusInverse[orderPaymentStatus]}
      </Tag>
    </div>
  </div>
);

OrderDetailsPaymentWallet.propTypes = {
  iconName: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  printPriceProps: PropTypes.object,
  orderPaymentStatus: PropTypes.number,
  editable: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};
