import { theme } from 'styles';

export const navigationWrapper = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1em',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: '1.5em',

  '.specs-container': {
    display: 'flex',
    alignItems: 'center',
    '& > button': {
      paddingLeft: 0,
    },
    '& .address-name': {
      fontSize: '1.375rem',
      fontWeight: 500,
      color: theme.background100,
      marginRight: '0.625em',
      overflow: 'hidden',
      display: '-webkit-box',
      WebkitLineClamp: 1,
      WebkitBoxOrient: 'vertical',
    },
  },
  '.actions-container': {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '0.5em',
  },

  '& .la-arrow-left': {
    fontSize: '1.375rem',
    color: theme.background100,
    marginTop: '-0.25em',
  },
};

export const informationWrapper = {
  borderBottom: `1px solid ${theme.gray500}`,
  borderRight: `1px solid ${theme.gray500}`,
  padding: '0 1.725em',
  '& label': {
    display: 'inline-block',
    color: theme.background200,
    marginBottom: '0.625em',
  },
  '& p': {
    marginBottom: '0.625em',
  },
  '& .ant-tabs-top > .ant-tabs-nav::before': {
    borderBottom: 'none',
  },
  '& .ant-tabs-nav-wrap': {
    marginBottom: '0.625em',
  },
  [theme.Desktop]: {
    paddingLeft: '1em',
  },
};

export const subTitle = {
  display: 'inline-block',
  color: theme.background100,
  fontSize: '1rem',
  fontWeight: 700,
  marginBottom: '0.5em',
};
