import { getText } from '@veraio/strank';
import { CheckButton } from 'components/UIExternal';

export const countryConfigurationColumns = (updateCryptoRestrictedCountry) => [
  {
    name: getText('country'),
    value: 'name',
  },
  {
    name: getText('cryptoBan'),
    render: (row) => (
      <CheckButton
        onChange={(value) => updateCryptoRestrictedCountry(row.id, value)}
        checked={row.isCryptoRestricted}
      />
    ),
    width: 90,
  },
];
