import { getText } from '@veraio/strank';
import moment from 'moment';
import { Tag } from 'components/UIExternal';
import { businessStatusColor, businessStatusInverse } from 'enums';

export const columns = () => [
  {
    title: getText('changedAt'),
    dataIndex: 'createdDate',
    render: (createdDate) => <span>{moment(createdDate).format('DD/MM/YYYY h:mm:ss')}</span>,
    width: 100,
  },
  {
    title: getText('changedBy'),
    dataIndex: 'createdBy',
    width: 120,
  },
  {
    title: getText('reason'),
    dataIndex: 'reason',
    render: (reason) => {
      const parts = reason?.split(/(\([^)]+\))/g);
      return (
          <div>
              {parts?.map((part, index) => {
                  if (part.match(/\([^)]+\)/))
                      return <b key={index}>{part}</b>;
                  return part;
              })}
          </div>
      );
    },
    width: 120,
  },
  {
    title: getText('status'),
    dataIndex: 'statusId',
    render: (statusId) => <Tag type={businessStatusColor[statusId]}>{getText(businessStatusInverse[statusId])}</Tag>,
    width: 100,
  },
];
