import { useTranslations } from '@veraio/strank';
import { TabPane, Tabs } from 'components/ui';
import { CountryConfigurations, GeneralConfigurations, CountryAreas } from './Tabs';
import { container } from './styles';

const Settings = () => {
  const { getText } = useTranslations();

  return (
    <div css={container}>
      <Tabs>
        <TabPane key={1} tab={getText('countryConfigurations')}>
          <CountryConfigurations />
        </TabPane>
        <TabPane key={2} tab={getText('generalConfigurations')}>
          <GeneralConfigurations />
        </TabPane>
        <TabPane key={3} tab={getText('countryAreas')}>
          <CountryAreas />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default Settings;
