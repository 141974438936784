import PropTypes from 'prop-types';
import { useTranslations } from '@veraio/strank';
import { TextChanged, getTextChangeProps } from 'components/UIExternal';

const AddressCardPreview = ({ oldData, newData }) => {
  const { getText } = useTranslations();

  const textChangeProps = getTextChangeProps(oldData, newData);

  return (
    <div>
      <label className="address-card-label">{getText('country')}:</label>
      <TextChanged {...textChangeProps('country')} />

      <label className="address-card-label">{getText('city')}:</label>
      <TextChanged {...textChangeProps('city')} />

      <label className="address-card-label">{getText('postcode')}:</label>
      <TextChanged {...textChangeProps('postcode')} />

      <label className="address-card-label">{getText('street')}:</label>
      <TextChanged {...textChangeProps('street')} />

      <label className="address-card-label">{getText('contactPerson')}:</label>
      <TextChanged {...textChangeProps('contactPerson')} />

      <label className="address-card-label">{getText('phoneNumber')}:</label>
      <TextChanged {...textChangeProps('phone')} />

      <label className="address-card-label">{getText('workingDays')}:</label>
      <TextChanged {...textChangeProps('workingDays')} />

      <label className="address-card-label">{getText('workingHours')}:</label>
      <TextChanged {...textChangeProps('workingHours')} />
    </div>
  );
};

AddressCardPreview.propTypes = {
  oldData: PropTypes.object,
  newData: PropTypes.object,
};

export default AddressCardPreview;
