import { createContext, useContext, useEffect, useReducer } from 'react';
import { omit } from 'lodash-es';
import { getToken, getRoles, hasAtLeastOneRole, USER_ROLES, renewSession } from '@oneecosystem/authenticate';
import { getReq } from 'services/axios/makeRequest';
import apiRoutes from 'config/apiRoutes';
import useError from 'services/errorHandling/useError';
import reducer from './reducer';

export const UserContext = createContext({});
export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const initialState = {
    isAuthenticated: !!getToken(),
    userInfo: null,
    error: null,
    loaded: false,
    isAuthorized: false,
    roles: null,
  };

  const [state, dispatch] = useReducer(reducer, initialState);
  const { setError } = useError();

  useEffect(() => {
    init();
  }, []);

  const init = async () => {
    const { pathname: path } = window.location;
    const isOnIdentityPage = path === '/authorization-callback' || path === '/logout';
    state?.isAuthenticated && !isOnIdentityPage ? loadUserData() : dispatch({ type: 'USER_INIT' });
  };

  const loadUserData = async () => {
    await renewSession();
    const [userData, usersMeError] = await getReq(`${apiRoutes.USER_ME}`);

    if (usersMeError) return setError(usersMeError);

    dispatch({
      type: 'USER_DATA_FETCHED',
      payload: {
        userInfo: userData,
        roles: getRoles(),
        isAuthenticated: true,
        isAuthorized: hasAtLeastOneRole([
          USER_ROLES.ADMINISTRATOR,
          USER_ROLES.DS_ADMIN,
          USER_ROLES.DS_AREA_MANAGER,
          USER_ROLES.DS_COUNTRY_MANAGER,
          USER_ROLES.DS_REGIONAL_DIRECTOR,
        ]),
      },
    });
  };

  return (
    <UserContext.Provider
      value={{
        ...omit(state, 'loaded'),
        loadUserData,
      }}>
      {state?.loaded && children}
    </UserContext.Provider>
  );
};
