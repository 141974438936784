import PropTypes from 'prop-types';
import { getText } from '@veraio/strank';
import { isFunction, isNumber } from '@veraio/core';
import { Tag as AntDTag, Select } from 'antd';
import { Tag } from 'components/UIExternal';
import { pageHeader, mainContainer, tag } from './styles';

export const PageHeader = ({ title, statusProps, actionsComponent }) => {
  const { defaultValue, value, options, onChange, tagProps } = statusProps ?? {};

  const tagRender = ({ closable, value: tagValue, onClose }) => {
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <AntDTag onMouseDown={onPreventMouseDown} closable={closable} onClose={onClose} css={tag}>
        <Tag type={tagProps?.type?.[tagValue]}>{getText(tagProps?.value?.[tagValue])}</Tag>
      </AntDTag>
    );
  };

  return (
    <header css={pageHeader}>
      <h1>{title}</h1>
      {statusProps && (
        <div css={mainContainer}>
          <Select
            allowClear
            showSearch={false}
            mode="multiple"
            placeholder={getText('filterBy')}
            value={isNumber(value) ? [value] : value}
            defaultValue={defaultValue}
            options={options}
            tagRender={tagRender}
            onChange={(val) => isFunction(onChange) && onChange(val)}
          />
        </div>
      )}
      {actionsComponent && actionsComponent}
    </header>
  );
};

PageHeader.propTypes = {
  title: PropTypes.string,
  statusProps: PropTypes.object,
  actionsComponent: PropTypes.element
};
