import { Table } from 'components/UIExternal';
import { TransactionsFilterBar } from 'components/screens';
import { getAllTransactions } from 'services/api/transactions';
import { filterKeys, getPaymentsColumns } from './config';
import { pageContainer } from './styles';

const Transactions = () => (
  <section css={pageContainer}>
    <Table
      getDataMethod={getAllTransactions}
      columns={getPaymentsColumns()}
      filterBar={TransactionsFilterBar}
      filterKeys={filterKeys}
    />
  </section>
);

export default Transactions;
