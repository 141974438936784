import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Rate, Row, Col } from 'antd';
import { isMobile } from 'react-device-detect';
import { useTranslations } from '@veraio/strank';
import { useCurrencies, printPrice } from '@oneecosystem/dealshaker-core';
import { Icon } from 'components/ui';
import { deliveryMethodSelect } from 'enums';
import { formatDefaultDate } from 'utils/valueFormatter';
import TickIcon from 'assets/images/icn-tick-filled.svg';
import {
  dealDescription,
  dealTitle,
  thumbnailContainer,
  thumbnail,
  favoriteIcon,
  mr12,
  dealRatingWrapper,
  starRatings,
  ratingsCountLabel,
  dealEndDateWrapper,
  couponIcon,
  link,
  verticalStyleWrapper,
  couponInfoWrapper,
  businessName,
} from './styles';

const ListingDeal = ({ deal, isVertical }) => {
  useCurrencies((currenciesState) => currenciesState.selectedCurrency);
  const { getText, getDynamicTranslation } = useTranslations();

  const {
    id,
    media,
    title,
    username,
    merchant,
    discountedPriceFiat,
    discountedPriceCrypto,
    rating,
    reviewsCount,
    availableCouponsCount,
    couponsCount,
    description,
    deliveryMethodId,
    dealEndDate,
    currencyCode,
  } = deal;

  return (
    <Link to={`/deal/${id}`} key={id} css={link}>
      <Row style={{ marginBottom: 32 }} gutter={[24, 24]}>
        <Col className="gutter-row" lg={isVertical ? 24 : 8} span={24}>
          <div css={thumbnailContainer}>
            <img src={media?.url} alt="Deal" css={thumbnail} />
            <Icon css={favoriteIcon} iconName="las la-heart" />
          </div>
        </Col>
        <Col css={isVertical && verticalStyleWrapper} lg={isVertical ? 24 : 16} span={24} className="gutter-row">
          <div style={{ height: '100%' }} className="flex-column space-between">
            <div>
              <h2 css={dealTitle}>
                {getDynamicTranslation(title)?.title}
                {discountedPriceFiat > 0 &&
                  ` - ${printPrice({
                    fiat: discountedPriceFiat,
                    fiatOriginCurrency: currencyCode,
                    fiatCrypto: discountedPriceCrypto,
                  })}`}
              </h2>
              <div css={isMobile || isVertical ? 'flex-column' : 'flex'}>
                <div className="flex">
                  <span css={businessName}>{isVertical ? merchant : username}</span>
                  <img src={TickIcon} css={mr12} alt="tick" />
                  <div className="deal-rating-wrapper mr12" css={dealRatingWrapper}>
                    <Rate css={starRatings} value={rating} disabled />
                    <Link css={ratingsCountLabel} to={`/${reviewsCount}`}>
                      {reviewsCount}
                    </Link>
                  </div>
                </div>
                <div css={couponInfoWrapper(isMobile, isVertical)}>
                  <Icon css={couponIcon(isMobile, isVertical)} iconName="las la-ticket-alt" />
                  <label>{getText('coupons')}</label>
                  <label className="bold-text">{getText('ofLeft', { availableCouponsCount, couponsCount })}</label>
                </div>
              </div>
              <p title={description} css={dealDescription}>
                {getDynamicTranslation(description)?.description}
              </p>
            </div>
            <div>
              <div css={dealEndDateWrapper(isVertical)}>
                <Icon iconName="lar la-clock" />
                <label>{getText('endsOn')} </label>
                <label
                  css={`
                    ${!isVertical && mr12} bold-text
                  `}>
                  {formatDefaultDate(dealEndDate)}
                </label>
                <Icon
                  className="delivery-icon"
                  iconName={deliveryMethodSelect[deal.deliveryMethodId]?.icon}
                  size={20}
                />
                <label>{getText(deliveryMethodSelect[deliveryMethodId]?.label)}</label>
                <Icon iconName="lar la-question-circle" />
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Link>
  );
};

ListingDeal.propTypes = {
  deal: PropTypes.object,
  isVertical: PropTypes.bool,
};

export default ListingDeal;
