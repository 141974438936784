import { pick } from '@veraio/core';
import { useTranslations } from '@veraio/strank';
import { printPrice, DEFAULT_FIAT_CURRENCY } from '@oneecosystem/dealshaker-core';
import { useOrders, selectedOrderPaymentDetails } from 'stores';
import { EXTERNAL_PAYMENT_METHODS, PAYMENT_METHODS_CURRENCIES } from 'enums';
import { OrderDetailsPaymentWallet } from './PaymentDetails';
import { paymentTypeBoxesConfig } from './config';
import { orderDetailsPaymentContainer } from './styles';
import { getPrecisionByExternalPaymentMethod } from 'utils';

export const OrderDetailsPayment = () => {
  const { getText } = useTranslations();
  const {
    payment,
    totalPriceCrypto,
    totalPriceFiat,
    currencyCode,
  } = useOrders((ordersState) => ({
    bankDetails: ordersState?.selectedOrder?.business?.bankDetails,
    ...pick(ordersState?.selectedOrder, [
      'totalPriceCrypto',
      'totalPriceFiat',
      'id',
      'statusId',
      'payment',
      'currencyCode',
    ]),
    editable: ordersState?.editable,
  }));
  const { isFiatManualPayment, isFiatPaymentWithUsdt } = selectedOrderPaymentDetails();
  const cashConfig = paymentTypeBoxesConfig[payment?.cashPaymentMethodId];
  const cryptoConfig = paymentTypeBoxesConfig[payment?.cryptoPaymentMethodId];
  const printPriceProps = {
    fiat: totalPriceFiat,
    fiatOriginCurrency: currencyCode,
    crypto: totalPriceCrypto,
    cryptoDesiredCurrency: PAYMENT_METHODS_CURRENCIES[payment?.cryptoPaymentMethodId]?.code,
    fiatPrecision: getPrecisionByExternalPaymentMethod(PAYMENT_METHODS_CURRENCIES[payment?.cashPaymentMethodId]),
  };

  const fiatPrintPriceProps = {
    ...printPriceProps,
    crypto: null,
    fiatDesiredCurrency: PAYMENT_METHODS_CURRENCIES[payment?.cashPaymentMethodId],
    fiatPrecision: getPrecisionByExternalPaymentMethod(PAYMENT_METHODS_CURRENCIES[payment?.cashPaymentMethodId]),
  };

  return (
    <>
      <h3 className="order-details-section-title">{getText('payment')}</h3>
      <section css={orderDetailsPaymentContainer} className="order-details-section">
        <div>
          <div className="order-details-payment-info-row">
            <h5 className="gray order-details-payment-info-label">{getText('type')}</h5>
            <span>
              {getText(cashConfig?.title)} + {getText(cryptoConfig?.title)}
            </span>
          </div>
        </div>

        <h5 className="gray order-details-payment-info-label">{getText('dealCurrency')}</h5>
        <div>
          <div className="order-details-payment-info-row">
            <h6 className="gray order-details-payment-info-label">{getText('dealTotalAmount')}</h6>
            <p>{printPrice({ ...printPriceProps, fiatDesiredCurrency: currencyCode, fiatPrecision: null })}</p>
          </div>

          {(currencyCode !== DEFAULT_FIAT_CURRENCY.code || isFiatPaymentWithUsdt) && !isFiatManualPayment && (
            <div className="order-details-payment-info-row">
              <h6 className="gray order-details-payment-info-label">{getText('yourPaymentCurrency')}</h6>

              <p>{printPrice({
                fiatDesiredCurrency: isFiatPaymentWithUsdt
                  ? EXTERNAL_PAYMENT_METHODS.USDT.code
                  : DEFAULT_FIAT_CURRENCY.code,
                ...printPriceProps,
              })}</p>

            </div>
          )}
        </div>

        <h4>{getText('paymentStatus')}</h4>
        <OrderDetailsPaymentWallet
          iconName={cryptoConfig?.icon}
          title={getText(cryptoConfig?.title)}
          subTitle={getText(cryptoConfig?.subTitle)}
          printPriceProps={{ ...printPriceProps, fiat: null }}
          orderPaymentStatus={payment?.cryptoPaymentStatus}
        />

        {!!totalPriceFiat && (
          <OrderDetailsPaymentWallet
            iconName={cashConfig?.icon}
            title={getText(cashConfig?.title)}
            subTitle={getText(cashConfig?.subTitle)}
            printPriceProps={fiatPrintPriceProps}
            orderPaymentStatus={payment?.cashPaymentStatus}
          />
        )}
      </section>
    </>
  );
};
