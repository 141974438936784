import { useRef } from 'react';
import { useTranslations } from '@veraio/strank';
import { useLocations, refetchCountries } from '@oneecosystem/dealshaker-core';
import { Table, Button, Modal, useUrlParams, useDeepEffect } from 'components/UIExternal';
import { AreasFilterBar } from 'components/screens';
import { mockTableRes } from 'utils/mockTableRes';
import useError from 'services/errorHandling/useError';
import { deleteCountryArea } from 'services';
import { areaColumns } from './config';
import { deletePopUpText, deleteActionButtons } from './styles';

const filterKeys = {
  countryId: 'countryId',
  areaName: 'areaName',
  areaId: 'areaId',
  areaToDelete: 'areaIdDelete',
};

export const CountryAreas = () => {
  const { getText } = useTranslations();
  const { setError } = useError();
  const { queryParams, setQueryParams, clearQueryParams } = useUrlParams();
  const countries = useLocations((locationsState) => locationsState.countries);
  const selectedCountry = countries.find((el) => el.id === queryParams?.[filterKeys.countryId]) ?? countries?.at(0);
  const modalRef = useRef();
  const tableRef = useRef();

  useDeepEffect(() => {
    tableRef.current?.refreshData();
  }, [selectedCountry]);

  const handleDeleteArea = async () => {
    const [, err] = await deleteCountryArea(queryParams?.[filterKeys.areaToDelete]);
    modalRef.current.close();
    clearQueryParams([filterKeys.areaToDelete]);
    err ? setError(err) : refetchCountries();
  };

  const handleSearch = (data, options) => {
    const areaName = options?.[filterKeys.areaName] && String(options?.[filterKeys.areaName]).toLowerCase();
    if (areaName) return data.filter((el) => el.areaName?.toLowerCase().includes(areaName));
    return data;
  };

  const handleOpenDeleteModal = (val) => {
    refetchCountries();
    setQueryParams({ [filterKeys.areaToDelete]: val });
    modalRef.current.open();
  };

  return (
    <>
      <Table
        ref={tableRef}
        columns={areaColumns((val) => setQueryParams({ [filterKeys.areaId]: val }), handleOpenDeleteModal)}
        getDataMethod={mockTableRes(selectedCountry?.areas ?? [], handleSearch)}
        updateOn={selectedCountry}
        filterBar={AreasFilterBar}
        filterKeys={filterKeys}
        defaultQueryParams={{ [filterKeys.countryId]: selectedCountry.id }}
      />
      <Modal ref={modalRef}>
        <p css={deletePopUpText}>{getText('sureRemoveArea')}</p>
        <div css={deleteActionButtons}>
          <Button small type="danger" onClick={handleDeleteArea}>
            {getText('delete')}
          </Button>
          <Button small type="secondary" onClick={() => modalRef.current.close()}>
            {getText('close')}
          </Button>
        </div>
      </Modal>
    </>
  );
};
