export const configurationsContainer = {
  padding: '2em 3em',
  display: 'flex',
  gap: '2em',
  alignItems: 'flex-start',
  flexFlow: 'row wrap',
  '&.mobileVersion': {
    maxHeight: '18.75rem',
  },
};

export const countriesDropdown = {
  width: '24rem',
  margin: '1em 0 0 auto',
};
