import { theme } from 'styles';

export const pageContainer = {
  width: '100% !important',
  zIndex: 100,
  '& > *': {
    marginBottom: '2.5em',
  },
};

export const container = {
  padding: '0 2em',
  '& .header': { marginTop: '2.5em' },

  '& .filterCollapse': {
    borderRadius: 28,
    width: '100%',
    border: 'none',
    borderBottom: ' none',
    marginBottom: '0.9375em',
    backgroundColor: theme.gray700,
  },

  [theme.Mobile]: {
    overflow: 'auto',
    maxWidth: '20rem',
    padding: '0px',
    '& .ant-table-wrapper': {
      padding: '0px !important',
    },
  },
};
