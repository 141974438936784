import PropTypes from 'prop-types';
import { Table } from 'antd';
import { pageContainer, container } from './styles';
import { columns } from './config';

export const DealStatusHistoryTab = ({ deal }) => (
  <section css={pageContainer}>
    <div css={container}>
      <Table
        style={{ paddingRight: 16, paddingLeft: 16, paddingTop: 16 }}
        showHeader
        responsive
        bordered={false}
        columns={columns()}
        rowKey={(record) => record.id}
        expandable={false}
        dataSource={deal?.statusHistory}
        align="center"
        size="default"
        ellipsis="enable"
      />
    </div>
  </section>
);

DealStatusHistoryTab.propTypes = { deal: PropTypes.object };
