import { theme } from 'styles';

export const pageWrapper = {
  '& .deal-name': {
    fontSize: '1.375rem',
    fontWeight: 500,
    color: theme.background100,
    marginRight: '0.9375em',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  },
  '& .deal-name-wrapper': {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'space-between',
    '.specs-container': {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      marginRight: '2em',
      marginBottom: '1em',
      '& button': {
        paddingLeft: '0',
      },
    },
    '.actions-container': {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '0.5em',
      marginBottom: '1em',
    },
  },
  '& .la-arrow-left': { fontSize: '1.375rem', color: theme.background100 },
};

export const tabs = {
  '& .ant-tabs-content-holder': { marginTop: '0.9375em' },
};

export const messageContainer = {
  backgroundColor: theme.red,
  textAlign: 'center',
  padding: '0.5em 1em',
  borderRadius: 10,

  '& > h3': {
    color: theme.white,
    marginBottom: 0,
  },
};
