import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Modal, Spin, Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import ReactPlayer from 'react-player';
import VideoThumbnail from 'react-video-thumbnail';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { useTranslations } from '@veraio/strank';
import { Button } from 'components/UIExternal';
import { ImageGalleryPanel, Icon } from 'components/ui';
import { modelContentWrapper, reasonArea, cancelButtonStyle } from 'components/ui/DeclineModal/styles';
import MediaTypesEnum from 'enums/MediaTypesEnum';
import useError from 'services/errorHandling/useError';
import { removeMedia } from 'services/api/mediaService';
import declineModalIcon from 'assets/images/DeclineModal.svg';
import { galleryModal, imagesPreview, video, youTubePlayIcon, modalActions } from './styles';

export const DealImagesTab = ({ media }) => {
  const { getText } = useTranslations();
  const { setError } = useError();
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [isHideMediaModalVisible, setIsHideMediaModalVisible] = useState(false);
  const [images, setImages] = useState(media);
  const [imagesForPreview, setImagesForPreview] = useState([]);
  const [reason, setReason] = useState('');
  const [imgIdToHide, setImgIdToHide] = useState(0);
  const [selectedImgIndex, setSelectedImgIndex] = useState(0);

  useEffect(() => {
    getMapImagesForPreview();
  }, [images]);
  const toggleHideMediaModal = () => setIsHideMediaModalVisible(!isHideMediaModalVisible);

  const getMapImages = images.map((image) => ({
    uid: image?.id,
    url: image?.thumbnailUrl,
    name: image?.id,
    status: 'done',
  }));

  const renderVideo = (url) => <ReactPlayer controls css={video} url={url} />;

  const renderThumbnail = (url) => (
    <div css={youTubePlayIcon}>
      <Icon iconName="las la-play" />
      <VideoThumbnail height={60} width={90} videoUrl={url} />
    </div>
  );

  const getMapImagesForPreview = () => {
    const newMedia = images.map((item) => {
      if (item.typeId === MediaTypesEnum.Link.id) {
        return {
          renderItem: () => renderVideo(`www.youtube.com/watch?v=${item.url}`),
          original: item.url,
          thumbnail: item.thumbnailUrl || item.url,
        };
      }
      if (item.typeId === MediaTypesEnum.Video.id) {
        return {
          renderItem: () => renderVideo(item.url),
          renderThumbInner: () => renderThumbnail(item.url),
          original: item.url,
          thumbnail: item.thumbnailUrl || item.url,
        };
      }
      return {
        thumbnail: item.thumbnailUrl || item.url,
        original: item.url,
      };
    });
    setImagesForPreview(newMedia);
  };

  const onRemoveImage = async (id) => {
    setDeleteInProgress(true);
    removeMedia(id, setError).then(() => {
      setImages(images.filter((img) => img?.id !== id));
      setDeleteInProgress(false);
    });
  };

  return deleteInProgress && !media?.length ? (
    <Spin size="large" />
  ) : (
    <div style={{ padding: 30 }}>
      <ImgCrop>
        <Upload
          showUploadList={{
            showDownloadIcon: true,
            downloadIcon: <EyeInvisibleOutlined title="Hide photo" />,
            showRemoveIcon: false,
          }}
          css={imagesPreview}
          listType="picture-card"
          fileList={getMapImages}
          onPreview={(e) => {
            const imgIndex = images.map((img) => img.id).indexOf(e.uid);
            setSelectedImgIndex(imgIndex > -1 ? imgIndex : 0);
            setIsPreviewModalVisible(true);
          }}
          onDownload={(e) => {
            setImgIdToHide(e.uid);
            toggleHideMediaModal();
          }}
        />
      </ImgCrop>
      <Modal
        centered
        width={1300}
        css={galleryModal}
        footer={null}
        open={isPreviewModalVisible}
        destroyOnClose
        onCancel={() => setIsPreviewModalVisible(false)}>
        <ImageGalleryPanel showNav startIndex={selectedImgIndex} items={imagesForPreview} />
      </Modal>
      <Modal
        open={isHideMediaModalVisible}
        width={580}
        zIndex={1100}
        onCancel={() => toggleHideMediaModal()}
        css={modelContentWrapper}
        footer={null}>
        <img src={declineModalIcon} className="iconModal" alt="prop" />
        <p className="section-subtitle">{getText('hidePhoto')}</p>
        <p className="textReasonStyle">{getText('pleaseProvideReasonWhyHidePhoto')}</p>
        <Input.TextArea
          placeholder={getText('reasonToHide')}
          value={reason}
          css={reasonArea}
          autoSize
          onChange={(e) => setReason(e.target.value)}
        />
        <div css={modalActions}>
          <Button
            type="secondary"
            small
            css={cancelButtonStyle}
            onClick={() => {
              toggleHideMediaModal();
              setReason('');
            }}>
            {getText('cancel')}
          </Button>
          <Button
            type="danger"
            small
            disabled={!reason}
            onClick={() => {
              // TODO: implement hide media when api is ready
              // handleDecline(reason);
              onRemoveImage(imgIdToHide);
              toggleHideMediaModal();
              setReason('');
            }}>
            {getText('hide')}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

DealImagesTab.propTypes = { media: PropTypes.array };
